
.header-main {
	padding: 20px 0;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 9999;

	.o-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		
		&:before, &:after {
			display: none;
		}

		@media (max-width: 768px) {
			justify-content: center;
		}
	}

	.header-main__logo {
		@media (min-width: 991px) {
			width: 200px;
			
			img {
				width: 100%;
			}
		}
	}
}
