/* ------------------------------------*\
    #WRAPPER
\*------------------------------------ */

.o-container {
  width: 100%;
  max-width: 100%;
  padding-right: $spacing-unit--small;
  padding-left: $spacing-unit--small;
  margin-right: auto;
  margin-left: auto;

  @include min-screen($breakpoint-large) {
    max-width: calc(#{$page-default-width} + (#{$spacing-unit} * 2));
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
  }
}
