/**
* Reset input styles.
*/
.input {
  -webkit-appearance: none;
  /* removing inner shadow on iOS inputs */
  -webkit-font-smoothing: inherit;
  box-sizing: border-box;
  transition: all .5s ease;
  background: none;
  border: none;
  color: $color-base;
  width: 100%;
  display: inline-block;
  font-family: $base-font-family;
  font-size: $base-font-size;
  padding: 0;
  /* remove browser defaults */
  vertical-align: middle;
  width: 100%;

  &:invalid {
    box-shadow: none;
    /* fixes mozilla red outline (shadow) */
  }

  &[readonly] {
    cursor: text;
  }

  &::-ms-clear {
    display: none;
    /* remove IE clear icon */
  }

  @mixin input-placeholder {
    color: $color-base;
  }

  &:not(:disabled) {

    &:hover {}

    &:focus {
      outline: none;
    }
  }
}

.input--primary {
  height: 50px;
  padding: 0 20px;
  color: #111;
  display: flex; 
  align-items: center;
  border: 1px solid #C4C4C4;
  border-radius: 24px;

  &:not(:disabled) {

    &:hover {}

    &:focus {}

    @mixin input-placeholder {
      color: $color-input-placeholder;
    }
  }

  &.error {
    border-color: red;
  }

  /**
   * @disabled
   */
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
}

.input-box {
  margin-bottom: 20px;
  position: relative;
  
  label.error,
  span.error {
    position: absolute;
    left: 17px;
    top: 100%;
    font-size: 12px;
    color: red;
    margin-top: 3px;
  }

  span.error {
    left: 10px;
    right: auto;
  }

  span.valid {
    position: absolute;
    left: 10px;
    top: 100%;
    font-size: 12px;
    color: green;
    margin-top: 3px;
  }
}

.selectbox {
  position: relative;
  display: block;
  width: 100%;

  span {
    position: absolute; 
    right: 15px;
    top: 50%;
    transform:translate(0,-50%);
    color: #C4C4C4;
    pointer-events: none;
  }

  select {
    cursor: pointer;
    padding: 0 20px!important;
    color: rgba(#111,.6);
  }
}