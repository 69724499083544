/* ------------------------------------*\
    #HEADINGS
\*------------------------------------ */

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'Roboto', sans-serif;
	
	>a {
		color: inherit;
	}
}

h1,
.h1 {
	font-size: 50px;
	line-height: 58px;
	font-weight: 900;

	@media(max-width: 991px) {
		font-size: 40px;
		line-height: 1.2;
	}
}

h2,
.h2 {
	font-size: 26px;
	line-height: 30px;
}

h3,
.h3 {
	// font-size: ;
}

h4,
.h4 {}

h5,
.h5 {}

h6,
.h6 {}
