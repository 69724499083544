
.hero {
	height: 100vh;
	padding: 200px 0;
	position: relative;
	overflow: hidden;

	h1 {
		margin-bottom: 20px;
		color: #fff;
	}

	.o-container {
		height: 100%;
		display: flex;
		align-items: center;
		position: relative;
		z-index: 5;
	}

	.pet-box {
		position: absolute;
		bottom: -2px;
		right: 40px;
		z-index: 3;

		.bubble1 {
			position: absolute;
			bottom: 100%;
			left: 95px;
		}

		.bubble2 {
			position: absolute;
			bottom: 80%;
			right: -10px;
		}
	}

	.button {
		margin-bottom: 30px;
	}

	.hero-bgr {
		position: absolute;
		z-index: 1;
		left: 50%;
		transform: translate(-50%,0);
		top: 0;
		width: 100%;
		min-width: 1300px;
	}

	@media(min-width: 991px) {
		article {
			margin-top: -100px;
		}
	}

	@media(max-width: 1200px) {
		.pet-box {
			right: 14px;
			max-width: 538px;
		}
	}

	@media(max-width: 991px) {
		height: auto;
		min-height: 100vh;
	}

	@media(max-width: 768px) {
		padding: 100px 0;
		padding-bottom: 0;

		.pet-box {
			// position: relative;
			margin-top: 160px;
			right: auto;
			left: 50%;
			transform: translate(-50%,0);

			.bubble1 {
				left: 0;
				transform: translate(-50%,0);

			}

			.bubble2 {
				right: 0;
				transform: translate(50%,0);
			}
		}

		.o-container {
			justify-content: center;
			text-align: center;
		}
	}

	@media(min-height: 700px) {
		.hero {
			min-height: 1000px;
		}
	}

	@media(max-width: 550px) {

		.pet-box {
			margin-top: 50px;
			position: fixed;
		}

		h1 {
			font-size: 28px;
		}
	}
}


.popup {
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	position: fixed;
	z-index: 9999;
	padding: 50px 0;
	visibility: hidden;
	opacity: 0;
	transition:all .3s ease;

	&.is-active {
		visibility: visible;
		opacity: 1;
	}
}

.popup__overlay {
	position: absolute;
	background: rgba(34, 34, 34, 0.7);
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;

}

.popup__content {
	background-color: #fff;
	border-radius: 15px;
	width: 100%;
	max-width: 550px;
	margin: 0 auto;
	position: relative;
	top: 50%;
	transform: translate(0,-50%);
	z-index: 3;
	padding: 90px 75px 60px;
	text-align: center;

	.close-popup {
		position: absolute;
		right: 35px;
		top: 35px;
		display: block;

		&:hover {
			path {
				fill:#F77F1C;
			}
		}
	}

	h2 {
		margin-bottom: 8px;
		color: #222222;
	}

	p {
		color: #555555;
		margin-bottom: 15px;
		font-size: 16px;
		line-height: 19px;
	}

	.button {
		margin-top: 20px;
	}


	@media(max-width: 991px) {
		max-width: 90%;
		padding: 80px 25px 50px;
	}
}