/* ------------------------------------*\
    #PAGE
\*------------------------------------ */


html {
  min-height: 100vh;
  color: $color-text;
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 400;
  background: #E5E5E5;
  font-family: 'Roboto', sans-serif;
}

address,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
p,
pre,
dl,
ol,
ul,
figure,
hr,
table,
fieldset {
  margin-bottom: $spacing-unit;
}

img {
  max-width: 100%;
}

strong {
  font-weight: 700;
}

main {
  overflow: hidden;
}