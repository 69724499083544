.button {
  -webkit-font-smoothing: inherit;
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  color: $color-base;
  transition: background-color .2s ease, color .2s ease;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  transition: all .2s ease;
  background-color: transparent;
  cursor: pointer;
  font-family: $base-font-family;
  text-decoration: none;
  line-height: 1;

  &:not(:disabled) {

    &:hover {}

    &:focus {}

    &:active {
      outline: none;
      box-shadow: none !important;
      text-decoration: none;
    }
  }

  &:disabled {
    opacity: .5;
    cursor: not-allowed !important;
  }
}


.button--primary {
  display: inline-flex;
  align-items: center;
  height: 50px;
  width: auto;
  padding: 0 32px;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  color: #F28D20;
  background: #FFFFFF;
  border-radius: 24px;

  &:not(:disabled) {

    &:hover,
    &:focus,
    &:active {
      color: #F28D20;
      background-color: darken(#FFFFFF ,15%);
    }
  }
}

.button--secondary {
  display: inline-flex;
  align-items: center;
  height: 50px;
  width: auto;
  padding: 0 32px;
  text-decoration: none;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  background: #F77F1C;
  border-radius: 24px;

  &:not(:disabled) {

    &:hover,
    &:focus,
    &:active {
      color: #fff;
      background-color: darken(#F77F1C ,15%);
    }
  }
}